import './index.css';
import './assets/styles/cbi-style.css';
import Layout from './pages/Layout';
import Playground from './pages/Playground';
function App() {
  return (
    <Layout/>
  );
}

export default App;
