import React from 'react'
import './style.css';


export const FormGroup = ({label ,children, _parentStyle,...props}) => {
  return (
    <div className='form-group' style={{..._parentStyle}}>
        <label htmlFor={props.id}>{label ?? children}</label>
        <input
            {...props}
        />
    </div>
  )
}


export default FormGroup;